import React from "react";
import { Grid } from "@material-ui/core";

import { DashboardChartModel } from "../../../Models";
import DoughnutChart from "../../../Components/Chart/DoughnutChart";
import DashboardCard from "./DashboardCard";

interface Props {
    dashboardData: DashboardChartModel[];
}
interface State { }
class Dashboard extends React.Component<Props, State> {
    render() {
        const { dashboardData } = this.props;

        return (
            <Grid item xs={12}>
                <Grid
                    container
                    direction="row"
                    spacing={2}
                >
                    {dashboardData.map((data) => {
                        return (
                            <Grid item xs={4} key={`DC-${data.id}`}>
                                <DashboardCard title={data.title}>
                                    <DoughnutChart id={data.id} data={data.data} />
                                </DashboardCard>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        );
    }
}

export default Dashboard;