import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";

import { AuthProviderService } from "../../Services";

interface Props extends RouteProps { }
interface State {
    isLoading: boolean;
    isAdmin: boolean;
    redirectPath: string;
}
class AdminRoute extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            isLoading: true,
            isAdmin: false,
            redirectPath: '/auth/accessdenied',
        };
    }

    async componentDidMount() {
        const { path } = this.props;
        AuthProviderService.setCallbackPath(path);

        const isLoggedIn = await AuthProviderService.isLoggedIn();

        if (!isLoggedIn) {
            AuthProviderService.login();
        } else {
            const isAdmin = await AuthProviderService.isAdminUser();
            this.setState({ isAdmin, isLoading: false });
        }
    }

    render() {
        const { isLoading, isAdmin, redirectPath } = this.state;

        if (isLoading) {
            return null;
        }

        if (!isAdmin) {
            const renderComponent = () => (<Redirect to={redirectPath} />);
            return <Route {...this.props} component={renderComponent} render={undefined} />;
        } else {
            return <Route {...this.props} />;
        }
    }
}

export default AdminRoute;