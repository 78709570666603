import React from "react";
import { Button } from "@material-ui/core";

interface Props {
    label: string;
    onClick: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    style?: React.CSSProperties;
}
interface State { }
class WhiteButton extends React.Component<Props, State> {
    render() {
        const { label, onClick, style } = this.props;

        const defaultStyle: any = { color: '#EE3A29', borderColor: '#EE3A29', textTransform: 'none' };

        let mergeStyle = { ...defaultStyle, ...style };

        return (
            <Button size="medium" variant="outlined" style={mergeStyle}
                onClick={onClick}>{label}</Button>
        );
    }
}

export default WhiteButton;