import { TableCell, Typography } from "@material-ui/core";

const TableBodyCell: React.FC<{ label: any, style?: React.CSSProperties }> = ({ label, style }) => {
    let cssStyle = { fontSize: 16, fontWeight: 400, color: '#000000' };

    if (style) {
        cssStyle = Object.assign(cssStyle, style);
    }

    return (
        <TableCell>
            <Typography style={cssStyle}>{label}</Typography>
        </TableCell>
    );
};

export default TableBodyCell;