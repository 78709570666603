import React from 'react';
import { Grid } from '@material-ui/core';

import { APIDetailDTO } from "../../../Models";
import TextField from '../../../Components/Text/TextField';

interface Props {
    apiDetail: APIDetailDTO;
}
interface State { }
class APIDetailForm extends React.Component<Props, State> {
    render() {
        const { apiDetail } = this.props;

        return (
            <form noValidate autoComplete="off">
                <div>
                    <Grid
                        container spacing={3}
                        alignItems="center"
                        justifyContent="flex-start"
                        style={{ padding: 10 }}
                    >
                        <Grid item xs={4}>
                            <TextField label="API Name"
                                value={apiDetail.ApiName} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField label="API EndPoint"
                                valueStyle={{ color: '#0C7F9A', textDecoration: 'underline' }} value={apiDetail.APIEndPoint} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField label="Protocol"
                                value={apiDetail.Protocol} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField label="Input Parameters"
                                value={apiDetail.InputParam} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField label="Output Parameters"
                                value={apiDetail.OutputParam} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField label="Response Code"
                                value={apiDetail.ResponseCode} />
                        </Grid>
                    </Grid>
                </div>
            </form>
        );
    }
}

export default APIDetailForm;