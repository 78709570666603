import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { SourceCredentialDTO } from "../../../Models";
import TextInputField from '../../../Components/TextInput/TextInputField';
import RedButton from '../../../Components/Button/RedButton';
import WhiteButton from '../../../Components/Button/WhiteButton';

interface Props {
    sourceCredential: SourceCredentialDTO;
    onCancelClick: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    onSubmit: ((sourceCredential: SourceCredentialDTO) => void);
}
interface State {
    sourceCredential: SourceCredentialDTO;
    formValidationState: { name: string, isValid: boolean }[];
}
class SourceCredentialForm extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        let isValid = false;

        if (props.sourceCredential && props.sourceCredential.CLIENT_ID) {
            isValid = true;
        }

        this.state = {
            sourceCredential: props.sourceCredential,
            formValidationState: [
                { name: 'Authorization', isValid },
                { name: 'CLIENT_SECRET', isValid },
                { name: 'API_URL', isValid },
                { name: 'TOKEN_URL', isValid },
                { name: 'CLIENT_ID', isValid },
                { name: 'grant_type', isValid },
                { name: 'Content-Type', isValid },
            ],
        };
    }

    render() {
        const { sourceCredential, formValidationState } = this.state;
        const { onCancelClick } = this.props;

        const isFormNotValid = formValidationState.some(p => p.isValid === false);

        return (
            <Box>
                <form noValidate autoComplete="off">
                    <div>
                        <Grid
                            container spacing={5}
                            alignItems="center"
                            justifyContent="flex-start"
                            style={{ padding: 10 }}
                        >
                            <Grid item xs={4}>
                                <TextInputField id="CLIENT_ID" label="Client ID" placeholder="Enter Client ID"
                                    value={sourceCredential.CLIENT_ID} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextInputField id="CLIENT_SECRET" label="Client Secret" placeholder="Enter Client Secret"
                                    value={sourceCredential.CLIENT_SECRET} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextInputField id="API_URL" label="API Url" placeholder="Enter API Url"
                                    value={sourceCredential.API_URL} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextInputField id="TOKEN_URL" label="Token Url" placeholder="Enter Token Url"
                                    value={sourceCredential.TOKEN_URL} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextInputField id="Authorization" label="Authorization" placeholder="Enter Authorization"
                                    value={sourceCredential.Authorization} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextInputField id="grant_type" label="Grant Type" placeholder="Enter Grant Type"
                                    value={sourceCredential.grant_type} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextInputField id="Content-Type" label="Content Type" placeholder="Enter Content Type"
                                    value={sourceCredential['Content-Type']} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <WhiteButton label="Cancel" onClick={onCancelClick} style={{ marginRight: 20 }} />
                                {isFormNotValid ?
                                    <RedButton disabled={true} label={sourceCredential.CLIENT_ID !== undefined ? 'Modify' : 'Add'} onClick={this.handleSubmit} /> :
                                    <RedButton label={sourceCredential.CLIENT_ID !== undefined ? 'Modify' : 'Add'} onClick={this.handleSubmit} />
                                }
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Box>
        );
    }

    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined, isValid: boolean) => {
        if (event) {
            this.setState(prevState => {
                const { id, value } = event.target;
                let sourceCredential: any = Object.assign({}, prevState.sourceCredential);
                sourceCredential[id] = value;

                let formValidationState = prevState.formValidationState;
                const index = formValidationState.findIndex(p => p.name === id);
                formValidationState[index] = { name: id, isValid: isValid };

                return { sourceCredential, formValidationState };
            });
        }
    }

    handleSubmit = () => {
        const { sourceCredential } = this.state;
        this.props.onSubmit(sourceCredential);
    }
}

export default SourceCredentialForm;