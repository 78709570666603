export enum ValidationType {
    NONE,
    REQUIRED,
}

export enum CustomEventType {
    SuccessAlert,
    ErrorAlert,
    ClearAlert,
    LoadingStart,
    LoadingStop,
}

export enum FileType {
    CSV = "text/csv",
}

export enum JobStatus {
    Running = 'Running',
    Success = 'Success',
    Failed = 'Failed',
}

export enum JobStatusColor {
    Running = '#E58707',
    Success = '#49A39D',
    Failed = '#F43D3D',
}

export enum Available {
    True = 'True',
    False = 'False',
}

export enum PageSizeKey {
    JobRunStatus = 'JobRunStatusTable_PageSize',
    DataAvailability = 'DataAvailabilityTable_PageSize',
    AuditLog = 'AuditLogTable_PageSize',
}