import CMSMiddlewareAPIService from "./CMSMiddlewareAPIService";
import { APIDetailDTO, JobDetailDTO, RunJobResponseDTO } from "../Models";
import ConfigurationService from "./ConfigurationService";
import MonitoringAuditTrailService from "./MonitoringAuditTrailService";
import CustomEventHandlerService from "./CustomEventHandlerService";
import { CustomEventType } from "../Common/Enums";
import moment from "moment";
import Utils from "../Common/Utils";

class JobManagementService extends CMSMiddlewareAPIService {
    async getAPIDetail(): Promise<APIDetailDTO> {
        const apiDetails = await ConfigurationService.getAllAPIDetails();

        let apiDetailFormDTO = {} as APIDetailDTO;

        const apiDetail = apiDetails.find(p => p.ApiName === 'UpdateCustomField');

        if (apiDetail) {
            apiDetailFormDTO = apiDetail;
        }

        return apiDetailFormDTO;
    }

    async runJob(): Promise<RunJobResponseDTO | null> {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);
        let runJobResponse: RunJobResponseDTO | null = null;

        const response = await this.GetAsync<RunJobResponseDTO>(`configuration/adhocjobrunrequest`);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
            runJobResponse = response.data;
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
        }

        CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);

        return runJobResponse;
    }

    async getAllJobDetails(): Promise<JobDetailDTO[]> {
        return MonitoringAuditTrailService.getAllJobDetails();
    }

    async getJobDetails(): Promise<JobDetailDTO[]> {
        const toDate = new Date();
        const fromDate = moment(toDate).subtract(1, 'M').toDate();

        const JobRunDateFrom = Utils.formatJSDate(fromDate);
        const JobRunDateTo = Utils.formatJSDate(toDate);

        const jobResponseDetail = await MonitoringAuditTrailService.getJobDetails({
            PageSize: 10, JobID: '', JobRunDate: '', JobRunDateFrom, JobRunDateTo,
        });

        let jobDetails: JobDetailDTO[] = [];

        if (jobResponseDetail) {
            jobDetails = jobResponseDetail.filterJobDetailsData;
        }

        return jobDetails;
    }
}

export default new JobManagementService();