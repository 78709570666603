import _ from "lodash";
import moment from "moment";

import CMSMiddlewareAPIService from "./CMSMiddlewareAPIService";
import { JobDetailDTO, AuditLogDTO, DashboardChartModel, JobRequestDetailDTO, JobResponseDetailDTO, JobChartResponseDTO, AuditLogRequestDTO, AuditLogResponseDTO } from "../Models";
import ChartModel from "../Components/Chart/ChartModel";
import { Available, JobStatus, JobStatusColor } from "../Common/Enums";

class MonitoringAuditTrailService extends CMSMiddlewareAPIService {
    async getAllJobDetails(): Promise<JobDetailDTO[]> {
        const response = await this.GetAsync<JobDetailDTO[]>(`monitoringaudittrail/getalljobdetails`);

        let jobDetails: JobDetailDTO[] = [];

        if (response.isSuccess) {
            jobDetails = response.data;

            jobDetails = jobDetails.sort((a, b) => {
                const jobID = Number.parseInt(b.JobID) - Number.parseInt(a.JobID);
                return +new Date(b.JobRunDate) - +new Date(a.JobRunDate) || jobID;
            });
        }

        return jobDetails;
    }

    async getJobChartDetails(): Promise<DashboardChartModel[]> {
        let dashboardCharts: DashboardChartModel[] = [];

        const jobStatusChartData: ChartModel[] = [];
        const fileAvailabilityChartData: ChartModel[] = [];
        const targetAvailabilityChartData: ChartModel[] = [];

        const response = await this.GetAsync<JobChartResponseDTO>(`monitoringaudittrail/getjobchartdetails`);

        if (response.isSuccess) {
            const { jobStatuses, jobFileAvailabilities, jobTargetAvailabilities } = response.data;

            jobStatuses.forEach((jobStatus) => {
                let color = '';

                switch (jobStatus.key) {
                    case JobStatus.Success:
                        color = JobStatusColor.Success;
                        break;
                    case JobStatus.Running:
                        color = JobStatusColor.Running;
                        break;
                    case JobStatus.Failed:
                        color = JobStatusColor.Failed;
                        break;
                    default:
                        break;
                }

                jobStatusChartData.push({
                    name: jobStatus.key,
                    value: jobStatus.value,
                    color,
                });
            });

            jobFileAvailabilities.forEach((jobFileAvailability) => {
                let name = '';
                let color = '';

                switch (jobFileAvailability.key) {
                    case Available.True:
                        name = 'File Available';
                        color = '#0C99E3';
                        break;
                    case Available.False:
                        name = 'File Not Available';
                        color = '#BFE9FF';
                        break;
                    default:
                        break;
                }

                fileAvailabilityChartData.push({
                    name,
                    value: jobFileAvailability.value,
                    color,
                });
            });

            jobTargetAvailabilities.forEach((jobTargetAvailability) => {
                let name = '';
                let color = '';

                switch (jobTargetAvailability.key) {
                    case Available.True:
                        name = 'Target Available';
                        color = '#ED9811';
                        break;
                    case Available.False:
                        name = 'Target Not Available';
                        color = '#F8D49A';
                        break;
                    default:
                        break;
                }

                targetAvailabilityChartData.push({
                    name,
                    value: jobTargetAvailability.value,
                    color,
                });
            });
        }

        dashboardCharts = [
            { id: 'JobRun', title: 'Job Run Status for Past 30 Days', data: jobStatusChartData },
            { id: 'FileAvailability', title: 'File Availability for Past 30 Days', data: fileAvailabilityChartData },
            { id: 'TargetAvailability', title: 'Target Availability for Past 30 Days', data: targetAvailabilityChartData },
        ];

        return dashboardCharts;
    }

    async getJobDetails(request: JobRequestDetailDTO): Promise<JobResponseDetailDTO | null> {
        const { PageSize, JobID, JobRunDate, JobRunDateFrom, JobRunDateTo } = request;
        const requestUrl = `monitoringaudittrail/getjobdetails?PageSize=${PageSize}&JobID=${JobID}&JobRunDate=${JobRunDate}&JobRunDateFrom=${JobRunDateFrom}&JobRunDateTo=${JobRunDateTo}`;
        const response = await this.GetAsync<JobResponseDetailDTO>(requestUrl);

        if (response.isSuccess) {
            return response.data;
        }

        return null;
    }

    async getAllAuditLogs(): Promise<AuditLogDTO[]> {
        const response = await this.GetAsync<AuditLogDTO[]>(`monitoringaudittrail/getallauditlogs`);

        let auditLogs: AuditLogDTO[] = [];

        if (response.isSuccess) {
            auditLogs = response.data;

            auditLogs = auditLogs.sort((a, b) => {
                return +new Date(b.ActionTimeStamp) - +new Date(a.ActionTimeStamp) || b.RowNumber - a.RowNumber;
            });
        }

        return auditLogs;
    }

    async getAuditLogs(request: AuditLogRequestDTO): Promise<AuditLogResponseDTO | null> {
        const { PageSize, JobID, LogID, ActionTimeStamp, JobRunDateFrom, JobRunDateTo } = request;
        const requestUrl = `monitoringaudittrail/getauditlogs?PageSize=${PageSize}&JobID=${JobID}&LogID=${LogID}&ActionTimeStamp=${ActionTimeStamp}&JobRunDateFrom=${JobRunDateFrom}&JobRunDateTo=${JobRunDateTo}`;
        const response = await this.GetAsync<AuditLogResponseDTO>(requestUrl);

        if (response.isSuccess) {
            return response.data;
        }

        return null;
    }

    async getDashboardData(jobDetails: JobDetailDTO[]): Promise<DashboardChartModel[]> {
        let dashboardCharts: DashboardChartModel[] = [];

        const jobStatusGroup = _.groupBy(jobDetails, p => p.JobStatus);
        const jobFileAvailabilityGroup = _.groupBy(jobDetails, p => p.FileAvailability);
        const jobTargetAvailabilityGroup = _.groupBy(jobDetails, p => p.TargetAvailability);

        const jobStatusChartData: ChartModel[] = [];
        const fileAvailabilityChartData: ChartModel[] = [];
        const targetAvailabilityChartData: ChartModel[] = [];

        for (let key in jobStatusGroup) {
            const jobStatuses = jobStatusGroup[key];
            const pastJobStatuses = jobStatuses.filter(p =>
                moment(p.JobRunDate) > moment().subtract(30, 'days'));

            let color = '';

            switch (key) {
                case JobStatus.Success:
                    color = JobStatusColor.Success;
                    break;
                case JobStatus.Running:
                    color = JobStatusColor.Running;
                    break;
                case JobStatus.Failed:
                    color = JobStatusColor.Failed;
                    break;
                default:
                    break;
            }

            jobStatusChartData.push({
                name: key,
                value: pastJobStatuses.length,
                color,
            });
        }

        for (let key in jobFileAvailabilityGroup) {
            const fileAvailabilities = jobFileAvailabilityGroup[key];
            const pastFileAvailabilities = fileAvailabilities.filter(p =>
                moment(p.JobRunDate) > moment().subtract(30, 'days'));

            let name = '';
            let color = '';

            switch (key) {
                case Available.True:
                    name = 'File Available';
                    color = '#0C99E3';
                    break;
                case Available.False:
                    name = 'File Not Available';
                    color = '#BFE9FF';
                    break;
                default:
                    break;
            }

            fileAvailabilityChartData.push({
                name,
                value: pastFileAvailabilities.length,
                color,
            });
        }

        for (let key in jobTargetAvailabilityGroup) {
            const targetAvailabilities = jobTargetAvailabilityGroup[key];
            const pastTargetAvailabilities = targetAvailabilities.filter(p =>
                moment(p.JobRunDate) > moment().subtract(30, 'days'));

            let name = '';
            let color = '';

            switch (key) {
                case Available.True:
                    name = 'Target Available';
                    color = '#ED9811';
                    break;
                case Available.False:
                    name = 'Target Not Available';
                    color = '#F8D49A';
                    break;
                default:
                    break;
            }

            targetAvailabilityChartData.push({
                name,
                value: pastTargetAvailabilities.length,
                color,
            });
        }

        dashboardCharts = [
            { id: 'JobRun', title: 'Job Run Status for Past 30 Days', data: jobStatusChartData },
            { id: 'FileAvailability', title: 'File Availability for Past 30 Days', data: fileAvailabilityChartData },
            { id: 'TargetAvailability', title: 'Target Availability for Past 30 Days', data: targetAvailabilityChartData },
        ];

        return dashboardCharts;
    }
}

export default new MonitoringAuditTrailService();