import React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Grid,
} from "@material-ui/core";

import { JobDetailDTO } from "../../../Models";
import TableHeaderCell from "../../../Components/Table/TableHeaderCell";
import TableBodyCell from "../../../Components/Table/TableBodyCell";
import StyledTableRow from "../../../Components/Table//StyledTableRow";
import LinkButton from "../../../Components/Button/LinkButton";
import Utils from "../../../Common/Utils";

interface Props extends RouteComponentProps<any> {
    jobDetails: JobDetailDTO[];
}
interface State { }
class JobDetailTable extends React.Component<Props, State> {
    render() {
        const { jobDetails } = this.props;

        const sortedData = jobDetails.sort((a, b) => {
            const jobID = Number.parseInt(b.JobID) - Number.parseInt(a.JobID);
            return +new Date(b.JobRunDate) - +new Date(a.JobRunDate) || jobID;
        });

        const paginatedData = Utils.paginate(sortedData, 10, 1);

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead style={{ backgroundColor: '#EEEEEE' }}>
                                <TableRow>
                                    <TableHeaderCell label="Job ID" minWidth={100} />
                                    <TableHeaderCell label="Job Run Date" minWidth={100} />
                                    <TableHeaderCell label="Status" minWidth={100} />
                                    <TableHeaderCell label="Logs" minWidth={100} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedData.map((row, i) => {
                                    let color = Utils.getColorByStatus(row.JobStatus);

                                    return (
                                        <StyledTableRow key={`JDT-${i}`}>
                                            <TableBodyCell label={row.JobID} />
                                            <TableBodyCell label={Utils.formatDate(row.JobRunDate)} />
                                            <TableBodyCell label={row.JobStatus} style={{ color }} />
                                            <TableCell>
                                                <LinkButton color="#065B90" onClick={() => this.handleViewClick(row)}>View</LinkButton>
                                            </TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        );
    }

    handleViewClick = (row: JobDetailDTO) => {
        this.props.history.push({ pathname: '/monitoringaudittrail', state: { tabName: 'AuditLogs', jobId: row.JobID } });
    }
}

export default withRouter(JobDetailTable);