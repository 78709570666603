import React from "react";
import { Grid, Typography } from "@material-ui/core";

interface Props {
    name: string;
    isSelected: boolean;
}
interface State { }
class TabLabel extends React.Component<Props, State> {
    render() {
        const { name, isSelected } = this.props;

        return (
            <Grid container alignItems="center" justifyContent="center">
                <Grid item>
                    <Typography style={{ fontSize: 16, fontWeight: 500, color: isSelected ? '#FFFFFF' : '#383838', padding: 5 }}>
                        {name}
                    </Typography>
                </Grid>
            </Grid>
        );
    }
}

export default TabLabel;