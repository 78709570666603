import React from "react";
import { Typography } from "@material-ui/core";

interface Props {
    label: string;
}
interface State { }
class PageHeader extends React.Component<Props, State> {
    render() {
        const { label } = this.props;

        return (
            <Typography style={{ color: '#2C2C2C', fontWeight: 600, fontSize: 24 }} noWrap>{label}</Typography>
        );
    }
}

export default PageHeader;