import CMSMiddlewareAPIService from "./CMSMiddlewareAPIService";
import CustomEventHandlerService from "./CustomEventHandlerService";
import { SourceCredentialDTO, APIDetailDTO } from "../Models";
import { CustomEventType } from "../Common/Enums";

class ConfigurationService extends CMSMiddlewareAPIService {
    async getAllCredentialDetails(): Promise<SourceCredentialDTO[]> {
        const response = await this.GetAsync<SourceCredentialDTO>(`configuration/getallcredentialsdetails`);

        let sourceCredentials: SourceCredentialDTO[] = [];

        if (response.isSuccess) {
            sourceCredentials.push(response.data);
        }

        return sourceCredentials;
    }

    async saveCredentialsDetails(sourceCredential: SourceCredentialDTO): Promise<SourceCredentialDTO | null> {
        const response = await this.PutAsync<SourceCredentialDTO>(`configuration/savecredentialsdetails?CLIENT_ID=${sourceCredential.CLIENT_ID}`, sourceCredential);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
            return sourceCredential;
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
            return null;
        }
    }

    async getAllAPIDetails(): Promise<APIDetailDTO[]> {
        const response = await this.GetAsync<APIDetailDTO[]>(`configuration/getallapidetails`);

        let apiDetails: APIDetailDTO[] = [];

        if (response.isSuccess) {
            apiDetails = response.data;

            apiDetails = apiDetails.sort((a, b) => {
                return a.ApiName.localeCompare(b.ApiName);
            });
        }

        return apiDetails;
    }

    async saveAPIDetails(apiDetail: APIDetailDTO): Promise<APIDetailDTO | null> {
        const response = await this.PutAsync<APIDetailDTO>(`configuration/saveapidetails?ApiId=${apiDetail.ApiID}`, apiDetail);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
            return apiDetail;
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
            return null;
        }
    }
}

export default new ConfigurationService();