import React from "react";
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Grid,
} from "@material-ui/core";

import { JobDetailDTO } from "../../../Models";
import TableHeaderCell from "../../../Components/Table/TableHeaderCell";
import TableBodyCell from "../../../Components/Table/TableBodyCell";
import StyledTableRow from "../../../Components/Table/StyledTableRow";
import LinkButton from "../../../Components/Button/LinkButton";
import { FileService } from "../../../Services";
import { FileType, PageSizeKey } from "../../../Common/Enums";
import Utils from "../../../Common/Utils";
import InfinitePagination from "../../../Components/Pagination/InfinitePagination";
import InlineDatePicker from "../../../Components/Date/InlineDatePicker";

interface Props {
    jobDetails: JobDetailDTO[];
    jobDetailsIndex: number;
    useLocalPagination: boolean;
    onJobDetailsNextClick: (() => void);
    onJobDetailsPreviousClick: (() => void);
    onPageSizeChange: (() => void);
    fromDate: Date | null;
    toDate: Date | null;
    onDateChange: ((id: string, selectedDate: Date | null) => void);
}
interface State {
    pageNumber: number;
}
class DataAvailabilityTable extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            pageNumber: 1,
        }
    }

    render() {
        const { jobDetails, jobDetailsIndex, useLocalPagination,
            fromDate, toDate } = this.props;
        const { pageNumber } = this.state;

        let enablePreviousButton = jobDetailsIndex > 0;
        let enableNextButton = true;
        let paginatedData = jobDetails;
        if (useLocalPagination) {
            enablePreviousButton = pageNumber > 1 ? true : false;
            const sortedData = jobDetails.sort((a, b) => {
                const jobID = Number.parseInt(b.JobID) - Number.parseInt(a.JobID);
                return +new Date(b.JobRunDate) - +new Date(a.JobRunDate) || jobID;
            });;

            const jobStatusPageSize = Utils.getPageSize(PageSizeKey.DataAvailability);
            paginatedData = Utils.paginate(sortedData, jobStatusPageSize, pageNumber);

            const nextPageData = Utils.paginate(sortedData, jobStatusPageSize, pageNumber + 1);

            if (nextPageData.length < 1) {
                enableNextButton = false;
            }
        }

        return (
            <Grid container direction="row" spacing={2}>
                <Grid
                    container item md={12}
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end">
                    <Grid item>
                        <InlineDatePicker id="fromDate" selectedDate={fromDate} label="From date"
                            onChange={this.handleDateChange} />
                    </Grid>
                    <Grid item>
                        <InlineDatePicker id="toDate" selectedDate={toDate} label="To date"
                            onChange={this.handleDateChange} />
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead style={{ backgroundColor: '#EEEEEE' }}>
                                        <TableRow>
                                            <TableHeaderCell label="Job ID" minWidth={100} />
                                            <TableHeaderCell label="Job Run Date" minWidth={100} />
                                            <TableHeaderCell label="Record Count" minWidth={100} />
                                            <TableHeaderCell label="File Processed" minWidth={100} />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {paginatedData.map((row, i) => (
                                            <StyledTableRow key={`DAT-${i}`}>
                                                <TableBodyCell label={row.JobID} />
                                                <TableBodyCell label={Utils.formatDate(row.JobRunDate)} />
                                                <TableBodyCell label={row.RecordCount} />
                                                <TableCell>
                                                    {row.ArchivedFileName ? <LinkButton onClick={() => this.handleFileDownload(row)}>View</LinkButton> : null}
                                                </TableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <InfinitePagination pageSizeKey={PageSizeKey.DataAvailability}
                            isPrevious={enablePreviousButton} isNext={enableNextButton}
                            onNextClick={this.handleNextClick} onPreviousClick={this.handlePreviousClick} onPageSizeChange={this.handlePageSizeChange} />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    handleFileDownload = async (row: JobDetailDTO) => {
        const fileKey = `raw/archive/${row.ArchivedFileName}`;
        const downloadURL = await FileService.getFile(fileKey, FileType.CSV);

        if (downloadURL) {
            window.open(downloadURL);
        }
    }

    handleNextClick = () => {
        const { onJobDetailsNextClick, useLocalPagination } = this.props;

        if (useLocalPagination) {
            this.setState(prevState => ({
                pageNumber: prevState.pageNumber + 1,
            }));
        } else {
            onJobDetailsNextClick();
        }
    }

    handlePreviousClick = () => {
        const { onJobDetailsPreviousClick, useLocalPagination } = this.props;

        if (useLocalPagination) {
            this.setState(prevState => ({
                pageNumber: prevState.pageNumber > 1 ? prevState.pageNumber - 1 : 1,
            }));
        } else {
            onJobDetailsPreviousClick();
        }
    }

    handlePageSizeChange = () => {
        const { onPageSizeChange, useLocalPagination } = this.props;

        if (useLocalPagination) {
            this.setState({ pageNumber: 1 });
        } else {
            this.setState({ pageNumber: 1 }, onPageSizeChange);
        }
    }

    handleDateChange = (selectedDate: Date | null, targetId?: string) => {
        if (targetId) {
            this.setState({ pageNumber: 1 }, () => {
                this.props.onDateChange(targetId, selectedDate);
            });
        }
    }
}

export default DataAvailabilityTable;