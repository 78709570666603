import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MenuModel from './MenuModel';

interface Props {
    menu: MenuModel;
    onClick: ((menu: MenuModel) => void);
}
interface State { }
class MenuItem extends React.Component<Props, State> {
    render() {
        const { menu, onClick } = this.props;

        const IconComponent = menu.icon;

        let selectedStyle: any = { padding: 20 };
        if (menu.isSelected) {
            selectedStyle = { backgroundColor: '#B5271A', padding: 20 };
        }

        return (
            <Box style={selectedStyle} display="flex" flexDirection="column" justifyContent="center" onClick={() => onClick(menu)}>
                <Box display="flex" justifyContent="center" style={{ color: '#FFFFFF', marginBottom: 5 }}>
                    <FontAwesomeIcon icon={IconComponent} size="lg" />
                </Box>
                <Box display="flex" justifyContent="center">
                    <Typography align="center" style={{ fontSize: 16, fontWeight: 400, color: '#FFFFFF' }}>{menu.name}</Typography>
                </Box>
            </Box>
        );
    }
}

export default MenuItem;