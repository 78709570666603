import { TableCell, Typography } from "@material-ui/core";

const TableHeaderCell: React.FC<{ label: string, minWidth?: number }> = ({ label, minWidth }) => {
    return (
        <TableCell style={{ minWidth: minWidth ?? 125 }}>
            <Typography style={{ fontSize: 16, fontWeight: 600, color: '#181818' }}>{label}</Typography>
        </TableCell>
    );
};

export default TableHeaderCell;