import React from 'react';
import { Grid } from '@material-ui/core';

import TabBar from "./TabBar";
import { TabModel, FormType } from "./TabModel";
import PageHeader from '../../Components/Text/PageHeader';
import Alert from '../../Components/Alert/Alert';
import { APIDetailDTO, SourceCredentialDTO } from '../../Models';
import { ConfigurationService, CustomEventHandlerService } from '../../Services';
import { CustomEventType } from '../../Common/Enums';


interface Props { }
interface State {
    currentTab: TabModel;
    showForm: FormType;
    sourceCredentials: SourceCredentialDTO[];
    sourceCredentialFormDTO: SourceCredentialDTO;
    apiDetails: APIDetailDTO[];
    apiDetailFormDTO: APIDetailDTO;
}
class Configuration extends React.Component<Props, State> {
    readonly Tabs: TabModel[] = [
        {
            name: 'Source Credentials',
            type: 'SourceCredential',
            index: 0,
        },
        {
            name: 'API Management',
            type: 'APIManagement',
            index: 1,
        },
    ];

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            currentTab: this.Tabs[0],
            showForm: 'None',
            sourceCredentials: [],
            sourceCredentialFormDTO: {} as SourceCredentialDTO,
            apiDetails: [],
            apiDetailFormDTO: {} as APIDetailDTO,
        }
    }

    async componentDidMount() {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        const sourceCredentials = await ConfigurationService.getAllCredentialDetails();

        this.setState({ sourceCredentials }, () => {
            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        });
    }

    render() {
        const {
            currentTab, showForm,
            sourceCredentials, sourceCredentialFormDTO,
            apiDetails, apiDetailFormDTO,
        } = this.state;

        return (
            <div>
                <Grid
                    container
                    direction="row"
                >
                    <Grid item xs={5}>
                        <PageHeader label="Configurations" />
                    </Grid>
                    <Grid item>
                        <Alert />
                    </Grid>
                </Grid>
                <TabBar
                    currentTab={currentTab} tabs={this.Tabs} onTabChange={this.handleTabChange}
                    showForm={showForm} onCancelClick={this.handleCancelClick}
                    sourceCredentials={sourceCredentials} sourceCredentialFormDTO={sourceCredentialFormDTO}
                    onCredentialModify={this.handleCredentialModifyClick} onCredentialFormSubmit={this.handleCredentialFormSubmit}
                    apiDetails={apiDetails} apiDetailFormDTO={apiDetailFormDTO}
                    onAPIDetailModify={this.handleAPIDetailModifyClick} onAPIDetailFormSubmit={this.handleAPIDetailFormSubmit}
                />
            </div>
        )
    }

    handleTabChange = async (selectedTab: TabModel) => {
        if (selectedTab.index === 1) {
            CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

            const apiDetails = await ConfigurationService.getAllAPIDetails();

            this.setState({ currentTab: selectedTab, apiDetails }, () => {
                CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
            });
        } else {
            this.setState({ currentTab: selectedTab });
        }
    }

    handleCancelClick = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        this.setState({ showForm: 'None' });
    }

    handleCredentialModifyClick = (sourceCredential: SourceCredentialDTO) => {
        this.setState({ showForm: 'None' }, () => {
            this.setState({ showForm: 'SourceCredential', sourceCredentialFormDTO: sourceCredential });
        });
    }

    handleCredentialFormSubmit = async (sourceCredential: SourceCredentialDTO) => {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        let { sourceCredentials } = this.state;
        const updatedSourceCredential = await ConfigurationService.saveCredentialsDetails(sourceCredential);

        if (updatedSourceCredential) {
            const index = sourceCredentials.findIndex(p => p.CLIENT_ID === sourceCredential.CLIENT_ID);
            sourceCredentials[index] = updatedSourceCredential;

            this.setState({ sourceCredentials }, () => {
                CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
            });
        } else {
            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        }
    }

    handleAPIDetailModifyClick = (apiDetail: APIDetailDTO) => {
        this.setState({ showForm: 'None' }, () => {
            this.setState({ showForm: 'APIManagement', apiDetailFormDTO: apiDetail });
        });
    }

    handleAPIDetailFormSubmit = async (apiDetail: APIDetailDTO) => {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        let { apiDetails } = this.state;
        const updatedApiDetail = await ConfigurationService.saveAPIDetails(apiDetail);

        if (updatedApiDetail) {
            const index = apiDetails.findIndex(p => p.ApiID === updatedApiDetail.ApiID);
            apiDetails[index] = updatedApiDetail;

            this.setState({ apiDetails }, () => {
                CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
            });
        } else {
            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        }
    }
}

export default Configuration;