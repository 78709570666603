import React from "react";
import { Box, Grid, Tab, Tabs } from "@material-ui/core";

import TabPanel from "../../Components/Tab/TabPanel";
import TabLabel from "../../Components/Tab/TabLabel";
import { FormType, TabModel } from "./TabModel";
import TabHeader from "../../Components/Tab/TabHeader";
import { APIDetailDTO, SourceCredentialDTO } from "../../Models";
import SourceCredentialTable from "./SourceCredential/SourceCredentialTable";
import SourceCredentialForm from "./SourceCredential/SourceCredentialForm";
import APIDetailTable from "./APIDetail/APIDetailTable";
import APIDetailForm from "./APIDetail/APIDetailForm";


interface Props {
    currentTab: TabModel;
    tabs: TabModel[];
    showForm: FormType;
    onTabChange: ((selectedTab: TabModel) => void);
    onCancelClick: ((event: React.MouseEvent<HTMLButtonElement> | undefined) => void);
    sourceCredentials: SourceCredentialDTO[];
    sourceCredentialFormDTO: SourceCredentialDTO;
    onCredentialModify: ((sourceCredential: SourceCredentialDTO) => void);
    onCredentialFormSubmit: ((sourceCredential: SourceCredentialDTO) => void);
    apiDetails: APIDetailDTO[];
    apiDetailFormDTO: APIDetailDTO;
    onAPIDetailModify: ((apiDetail: APIDetailDTO) => void);
    onAPIDetailFormSubmit: ((apiDetail: APIDetailDTO) => void);
}
interface State { }
class TabBar extends React.Component<Props, State> {
    render() {
        const {
            tabs, currentTab, showForm, onCancelClick,
            sourceCredentials, sourceCredentialFormDTO, onCredentialModify, onCredentialFormSubmit,
            apiDetails, apiDetailFormDTO, onAPIDetailModify, onAPIDetailFormSubmit,
        } = this.props;

        return (
            <Box mt={2}>
                <Tabs
                    value={currentTab.index}
                    onChange={this.handleTabChange}
                    aria-label="Configuration Tabs"
                    style={{
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        borderBottom: '1px solid #DEDEDE',
                        boxShadow: 'none',
                    }}
                    TabIndicatorProps={{
                        style: {
                            display: 'none',
                        },
                    }}
                >
                    {tabs.map((tab, i) => {
                        const tabProps = {
                            id: `standard-tab-${i}`,
                            'aria-controls': `standard-tabpanel-${i}`,
                        };

                        const isSelected = currentTab.index === i;

                        const tabStyle: React.CSSProperties = {
                            backgroundColor: '#F4F4F4',
                            textTransform: 'none',
                            marginRight: 10,
                            border: '1px solid #D5D5D5',
                            borderRadius: '6px 6px 0px 0px',
                        };

                        if (isSelected) {
                            tabStyle.backgroundColor = '#EE3A29';
                            tabStyle.border = 'none';
                        }

                        return (
                            <Tab {...tabProps} key={tabProps.id}
                                label={
                                    <TabLabel
                                        name={tab.name}
                                        isSelected={isSelected}
                                    />
                                }
                                style={tabStyle}
                            />
                        );
                    })}
                </Tabs>
                <TabPanel value={currentTab.index} index={0}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item md={12}>
                            <TabHeader label={currentTab.name} />
                        </Grid>
                        <Grid item md={12}>
                            <SourceCredentialTable sourceCredentials={sourceCredentials}
                                onCredentialModify={onCredentialModify} />
                        </Grid>
                        <Grid item md={12}>
                            {showForm === currentTab.type ?
                                <SourceCredentialForm sourceCredential={sourceCredentialFormDTO}
                                    onCancelClick={onCancelClick} onSubmit={onCredentialFormSubmit} />
                                : <></>}
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={currentTab.index} index={1}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item md={12}>
                            <TabHeader label={currentTab.name} />
                        </Grid>
                        <Grid item md={12}>
                            <APIDetailTable apiDetails={apiDetails}
                                onAPIModify={onAPIDetailModify} />
                        </Grid>
                        <Grid item md={12}>
                            {showForm === currentTab.type ?
                                <APIDetailForm apiDetail={apiDetailFormDTO}
                                    onCancelClick={onCancelClick} onSubmit={onAPIDetailFormSubmit} />
                                : <></>}
                        </Grid>
                    </Grid>
                </TabPanel>
            </Box>
        )
    }

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const { tabs } = this.props;
        const selectedTab = tabs.find(p => p.index === newValue);

        if (selectedTab) {
            this.props.onTabChange(selectedTab);
        }
    }
}

export default TabBar;