import React from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


interface Props {
    id: string;
    value?: string | number;
    options: string[] | number[];
    onChange: ((id: string, value: string) => void);
}
interface State { }
class SimpleSelect extends React.Component<Props, State> {
    render() {
        const { id, value, options } = this.props;

        return (
            <FormControl variant="outlined" size="small">
                <Select
                    id={id}
                    value={value}
                    onChange={this.handleChange}
                    IconComponent={(props) => <FontAwesomeIcon {...props} icon={faChevronDown} style={{ marginTop: 3, marginRight: 5, color: '#EE3A29' }} />}
                >
                    {options.map((option, i) => {
                        return <MenuItem key={`opt-${option}`} value={option}>{option}</MenuItem>;
                    })}
                </Select>
            </FormControl>
        );
    }

    handleChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        const { id, onChange } = this.props;
        onChange(id, event.target.value as string);
    }
}

export default SimpleSelect;