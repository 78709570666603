import { faClock, faCog, faNetworkWired } from '@fortawesome/free-solid-svg-icons';

import MenuModel from "./MenuModel";

const Menus: MenuModel[] = [
    {
        name: 'Monitoring Audit Trail',
        path: '/monitoringaudittrail',
        icon: faClock,
    },
    {
        name: 'Configurations',
        path: '/configuration',
        icon: faCog,
    },
    {
        name: 'Job Management',
        path: '/jobmanagement',
        icon: faNetworkWired,
    },
];

export default Menus;