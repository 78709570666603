import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import Layout from './Components/Layout';
import AdminRoute from './Components/Route/AdminRoute';

import {
  MonitoringAuditTrail,
  Configuration,
  JobManagement,
  AuthCallbackPage,
  AccessDeniedPage,
} from './Pages';

function App() {
  return (
    <Router>
      <Switch>
        <Redirect exact from="/" to="/monitoringaudittrail" />
        <Route path={[
          "/monitoringaudittrail", "/configuration", "/jobmanagement",
        ]}>
          <Layout>
            <Switch>
              <AdminRoute path="/monitoringaudittrail" component={MonitoringAuditTrail} />
              <AdminRoute path="/configuration" component={Configuration} />
              <AdminRoute path="/jobmanagement" component={JobManagement} />
            </Switch>
          </Layout>
        </Route>
        <Route path={[
          "/auth/callback", "/auth/accessdenied",
        ]}>
          <Layout>
            <Switch>
              <Route path="/auth/callback" component={AuthCallbackPage} />
              <Route path="/auth/accessdenied" component={AccessDeniedPage} />
            </Switch>
          </Layout>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
