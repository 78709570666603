import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { APIDetailDTO } from "../../../Models";
import TextInputField from '../../../Components/TextInput/TextInputField';
import RedButton from '../../../Components/Button/RedButton';
import WhiteButton from '../../../Components/Button/WhiteButton';

interface Props {
    apiDetail: APIDetailDTO;
    onCancelClick: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    onSubmit: ((apiDetail: APIDetailDTO) => void);
}
interface State {
    apiDetail: APIDetailDTO;
    formValidationState: { name: string, isValid: boolean }[];
}
class APIDetailForm extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        let isValid = false;

        if (props.apiDetail && props.apiDetail.ApiID) {
            isValid = true;
        }

        this.state = {
            apiDetail: props.apiDetail,
            formValidationState: [
                { name: 'ApiID', isValid },
            ],
        };
    }

    render() {
        const { apiDetail, formValidationState } = this.state;
        const { onCancelClick } = this.props;

        const isFormNotValid = formValidationState.some(p => p.isValid === false);

        return (
            <Box>
                <form noValidate autoComplete="off">
                    <div>
                        <Grid
                            container spacing={5}
                            alignItems="center"
                            justifyContent="flex-start"
                            style={{ padding: 10 }}
                        >
                            <Grid item xs={4}>
                                <TextInputField id="ApiName" label="API Name" placeholder="Enter API Name"
                                    value={apiDetail.ApiName} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextInputField id="APIEndPoint" label="API EndPoint" placeholder="Enter API EndPoint Name"
                                    value={apiDetail.APIEndPoint} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextInputField id="Protocol" label="Protocol" placeholder="Enter Protocol Name"
                                    value={apiDetail.Protocol} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextInputField id="InputParam" label="Input Parameters" placeholder="Enter Input Parameter Name"
                                    value={apiDetail.InputParam} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextInputField id="OutputParam" label="Output Parameters" placeholder="Enter Output Parameter Name"
                                    value={apiDetail.OutputParam} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextInputField id="ResponseCode" label="Response Code" placeholder="Enter Response Code"
                                    value={apiDetail.ResponseCode} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <WhiteButton label="Cancel" onClick={onCancelClick} style={{ marginRight: 20 }} />
                                {isFormNotValid ?
                                    <RedButton disabled={true} label={apiDetail.ApiID !== undefined ? 'Modify' : 'Add'} onClick={this.handleSubmit} /> :
                                    <RedButton label={apiDetail.ApiID !== undefined ? 'Modify' : 'Add'} onClick={this.handleSubmit} />
                                }
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Box>
        );
    }

    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined, isValid: boolean) => {
        if (event) {
            this.setState(prevState => {
                const { id, value } = event.target;
                let apiDetail: any = Object.assign({}, prevState.apiDetail);
                apiDetail[id] = value;

                let formValidationState = prevState.formValidationState;
                const index = formValidationState.findIndex(p => p.name === id);
                formValidationState[index] = { name: id, isValid: isValid };

                return { apiDetail, formValidationState };
            });
        }
    }

    handleSubmit = () => {
        const { apiDetail } = this.state;
        this.props.onSubmit(apiDetail);
    }
}

export default APIDetailForm;