import React from 'react';
import Link from "@material-ui/core/Link";

interface Props {
    color: string;
    onClick: ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void);
}
interface State { }
class LinkButton extends React.Component<Props, State> {
    public static defaultProps = {
        color: '#EE3A29',
    }

    render() {
        const { onClick, children, color } = this.props;

        return (
            <Link underline="always" style={{ fontSize: 14, fontWeight: 500, color, cursor: 'pointer' }} onClick={onClick}>{children}</Link>
        );
    }
}

export default LinkButton;