import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import MenuModel from './MenuModel';
import MenuItem from './MenuItem';
import Menus from './Menus';

interface Props extends RouteComponentProps<any> { }
interface State { }
class Menu extends React.Component<Props, State> {
    render() {
        const { location } = this.props;

        return Menus.map((menu) => {
            menu.isSelected = menu.path === location.pathname;

            return <MenuItem key={menu.name} menu={menu} onClick={this.handleMenuClick} />;
        });
    }

    handleMenuClick = (menu: MenuModel) => {
        this.props.history.push(menu.path);
    }
}

export default withRouter(Menu);