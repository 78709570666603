import React from "react";
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
} from "@material-ui/core";

import { APIDetailDTO } from "../../../Models";
import TableHeaderCell from "../../../Components/Table/TableHeaderCell";
import TableBodyCell from "../../../Components/Table/TableBodyCell";
import StyledTableRow from "../../../Components/Table/StyledTableRow";
import LinkButton from "../../../Components/Button/LinkButton";


interface Props {
    apiDetails: APIDetailDTO[];
    onAPIModify: ((apiDetail: APIDetailDTO) => void);
}
interface State { }
class APIDetailTable extends React.Component<Props, State> {
    render() {
        const { apiDetails, onAPIModify } = this.props;

        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead style={{ backgroundColor: '#EEEEEE' }}>
                        <TableRow>
                            <TableHeaderCell label="API Name" minWidth={100} />
                            <TableHeaderCell label="API EndPoint" minWidth={100} />
                            <TableHeaderCell label="Protocol" minWidth={100} />
                            <TableHeaderCell label="Input Parameters" minWidth={100} />
                            <TableHeaderCell label="Output Parameters" minWidth={100} />
                            <TableHeaderCell label="Response Code" minWidth={100} />
                            <TableHeaderCell label="" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {apiDetails.map((row, i) => (
                            <StyledTableRow key={`ADT-${i}`}>
                                <TableBodyCell label={row.ApiName} />
                                <TableBodyCell label={row.APIEndPoint} style={{ color: '#0C7F9A', textDecoration: 'underline' }} />
                                <TableBodyCell label={row.Protocol} />
                                <TableBodyCell label={row.InputParam} />
                                <TableBodyCell label={row.OutputParam} />
                                <TableBodyCell label={row.ResponseCode} />
                                <TableCell>
                                    <LinkButton color="#868686" onClick={() => onAPIModify(row)}>Modify</LinkButton>
                                </TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default APIDetailTable;