import React from "react";
import { Box, Tab, Tabs } from "@material-ui/core";

import TabPanel from "../../Components/Tab/TabPanel";
import TabLabel from "../../Components/Tab/TabLabel";
import { TabModel } from "./TabModel";
import SourceSystemAvailabilityTable from "./SourceSystemAvailability/SourceSystemAvailabilityTable";
import { AuditLogDTO, JobDetailDTO } from "../../Models";
import DataAvailabilityTable from "./DataAvailability/DataAvailabilityTable";
import AuditLogTable from "./AuditLogs/AuditLogTable";

interface Props {
    currentTab: TabModel;
    tabs: TabModel[];
    onTabChange: ((selectedTab: TabModel) => void);
    jobDetails: JobDetailDTO[];
    jobDetailsIndex: number;
    onJobDetailsNextClick: (() => void);
    onJobDetailsPreviousClick: (() => void);
    auditLogs: AuditLogDTO[];
    auditLogIndex: number;
    onAuditLogNextClick: (() => void);
    onAuditLogPreviousClick: (() => void);
    selectedDate: Date | null;
    fromDate: Date | null;
    toDate: Date | null;
    onDateChange: ((id: string, selectedDate: Date | null) => void);
    onPageSizeChange: ((selectedTab: TabModel) => void);
    isLocalPagination: boolean;
}
interface State { }
class TabBar extends React.Component<Props, State> {
    render() {
        const {
            tabs, currentTab,
            jobDetails, auditLogs, jobDetailsIndex, auditLogIndex,
            onJobDetailsNextClick, onJobDetailsPreviousClick,
            selectedDate, onPageSizeChange, isLocalPagination,
            fromDate, toDate, onDateChange, onAuditLogNextClick, onAuditLogPreviousClick
        } = this.props;

        return (
            <Box mt={2}>
                <Tabs
                    value={currentTab.index}
                    onChange={this.handleTabChange}
                    aria-label="Configuration Tabs"
                    style={{
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        borderBottom: '1px solid #DEDEDE',
                        boxShadow: 'none',
                    }}
                    TabIndicatorProps={{
                        style: {
                            display: 'none',
                        },
                    }}
                >
                    {tabs.map((tab, i) => {
                        const tabProps = {
                            id: `standard-tab-${i}`,
                            'aria-controls': `standard-tabpanel-${i}`,
                        };

                        const isSelected = currentTab.index === i;

                        const tabStyle: React.CSSProperties = {
                            backgroundColor: '#F4F4F4',
                            textTransform: 'none',
                            marginRight: 10,
                            border: '1px solid #D5D5D5',
                            borderRadius: '6px 6px 0px 0px',
                        };

                        if (isSelected) {
                            tabStyle.backgroundColor = '#EE3A29';
                            tabStyle.border = 'none';
                        }

                        return (
                            <Tab {...tabProps} key={tabProps.id}
                                label={
                                    <TabLabel
                                        name={tab.name}
                                        isSelected={isSelected}
                                    />
                                }
                                style={tabStyle}
                            />
                        );
                    })}
                </Tabs>
                <TabPanel value={currentTab.index} index={0}>
                    <SourceSystemAvailabilityTable jobDetails={jobDetails} jobDetailsIndex={jobDetailsIndex}
                        onJobDetailsNextClick={onJobDetailsNextClick} onJobDetailsPreviousClick={onJobDetailsPreviousClick}
                        onPageSizeChange={() => onPageSizeChange(currentTab)} useLocalPagination={isLocalPagination}
                        fromDate={fromDate} toDate={toDate} onDateChange={onDateChange} />
                </TabPanel>
                <TabPanel value={currentTab.index} index={1}>
                    <DataAvailabilityTable jobDetails={jobDetails} jobDetailsIndex={jobDetailsIndex}
                        onJobDetailsNextClick={onJobDetailsNextClick} onJobDetailsPreviousClick={onJobDetailsPreviousClick}
                        onPageSizeChange={() => onPageSizeChange(currentTab)} useLocalPagination={isLocalPagination}
                        fromDate={fromDate} toDate={toDate} onDateChange={onDateChange} />
                </TabPanel>
                <TabPanel value={currentTab.index} index={2}>
                    <AuditLogTable auditLogs={auditLogs} auditLogIndex={auditLogIndex}
                        onAuditLogNextClick={onAuditLogNextClick} onAuditLogPreviousClick={onAuditLogPreviousClick}
                        onPageSizeChange={() => onPageSizeChange(currentTab)} useLocalPagination={isLocalPagination}
                        selectedDate={selectedDate} onDateChange={onDateChange} />
                </TabPanel>
            </Box>
        )
    }

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const { tabs } = this.props;
        const selectedTab = tabs.find(p => p.index === newValue);

        if (selectedTab) {
            this.props.onTabChange(selectedTab);
        }
    }
}

export default TabBar;