import { ValidationType } from "./Enums";

export default class ValidationUtils {
    static validate(validationType: ValidationType, value: any): { isValid: boolean, message: string } {
        switch (validationType) {
            case ValidationType.NONE:
                return { isValid: true, message: '' };
            case ValidationType.REQUIRED:
                return { isValid: value && value.trim().length > 0, message: 'The value is required' };
            default:
                return { isValid: false, message: 'Unknown validation type' };
        }
    }
}