import React from 'react';
import { AppBar, Toolbar, Divider, Box, Typography } from '@material-ui/core';

import EliLillyLogo from '../../Assets/Lilly_Logo.svg';
import Menu from './Menu';
import MenuDrawer from './Menu/MenuDrawer';

interface Props {
    showMenu: boolean;
}
interface State {
    isDrawerOpen: boolean;
}
class Header extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            isDrawerOpen: false,
        };
    }

    render() {
        const { isDrawerOpen } = this.state;
        const { showMenu } = this.props;

        return (
            <>
                <AppBar position="static" style={{ backgroundColor: '#FFFFFF' }}>
                    <Toolbar>
                        <img src={EliLillyLogo} alt="logo" height="64px" width="117px" style={{ margin: 10 }} />
                        <Divider orientation="vertical" flexItem style={{ margin: 10 }} />
                        <Typography style={{ color: '#EE3A29', fontWeight: 500, fontSize: 28 }}>CMS</Typography>
                        <Typography>&nbsp;&nbsp;</Typography>
                        <Typography style={{ color: '#000000', fontWeight: 700, fontSize: 28 }}>Middleware</Typography>
                    </Toolbar>
                    {showMenu ? <Box display={{ xs: 'none', md: 'block' }} >
                        <Box display="flex" style={{ backgroundColor: '#EE3A29', cursor: 'pointer' }}>
                            <Menu />
                        </Box>
                    </Box> : null}
                </AppBar>
                <MenuDrawer isOpen={isDrawerOpen} onClose={() => this.setState({ isDrawerOpen: false })} />
            </>
        )
    }
}

export default Header;