import axios from 'axios';

import { AuthProviderService } from '../Services';
import Utils from '../Common/Utils';

class AxiosMiddleware {
    registerInterceptors() {
        const env = Utils.getEnvVars();

        axios.interceptors.request.use(async (config) => {
            if (config.baseURL === env.API_URL) {
                const authTokenModel = await AuthProviderService.getAccessToken();
                const authorization = `Bearer ${authTokenModel.accessToken}`;

                if (config.headers) {
                    config.headers.Authorization = authorization;
                } else {
                    config.headers = {
                        Authorization: authorization
                    };
                }
            }

            return config;
        });
    }
}

export default new AxiosMiddleware();