import React from "react";
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Grid,
} from "@material-ui/core";

import { AuditLogDTO } from "../../../Models";
import TableHeaderCell from "../../../Components/Table/TableHeaderCell";
import TableBodyCell from "../../../Components/Table/TableBodyCell";
import StyledTableRow from "../../../Components/Table/StyledTableRow";
import Utils from "../../../Common/Utils";
import { PageSizeKey } from "../../../Common/Enums";
import InfinitePagination from "../../../Components/Pagination/InfinitePagination";
import InlineDatePicker from "../../../Components/Date/InlineDatePicker";

interface Props {
    auditLogs: AuditLogDTO[];
    auditLogIndex: number;
    useLocalPagination: boolean;
    onAuditLogNextClick: (() => void);
    onAuditLogPreviousClick: (() => void);
    onPageSizeChange: (() => void);
    selectedDate: Date | null;
    onDateChange: ((id: string, selectedDate: Date | null) => void);
}
interface State {
    pageNumber: number;
}
class AuditLogTable extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            pageNumber: 1,
        }
    }

    render() {
        const { auditLogs, auditLogIndex, useLocalPagination, selectedDate } = this.props;
        const { pageNumber } = this.state;

        let enablePreviousButton = auditLogIndex > 0;
        let enableNextButton = true;
        let paginatedData = auditLogs;
        if (useLocalPagination) {
            enablePreviousButton = pageNumber > 1 ? true : false;
            const sortedData = auditLogs.sort((a, b) => {
                return +new Date(b.ActionTimeStamp) - +new Date(a.ActionTimeStamp) || b.RowNumber - a.RowNumber;
            });

            const auditLogPageSize = Utils.getPageSize(PageSizeKey.AuditLog);
            paginatedData = Utils.paginate(sortedData, auditLogPageSize, pageNumber);

            const nextPageData = Utils.paginate(sortedData, auditLogPageSize, pageNumber + 1);

            if (nextPageData.length < 1) {
                enableNextButton = false;
            }
        }

        return (
            <Grid container direction="row" spacing={2}>
                <Grid
                    container item md={12}
                    alignItems="center"
                    justifyContent="flex-end">
                    <Grid item>
                        <InlineDatePicker id="selectedDate" selectedDate={selectedDate} label="From date"
                            onChange={this.handleDateChange} />
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead style={{ backgroundColor: '#EEEEEE' }}>
                                        <TableRow>
                                            <TableHeaderCell label="Job ID" minWidth={100} />
                                            <TableHeaderCell label="Job Run Date" minWidth={100} />
                                            <TableHeaderCell label="Action Type" minWidth={100} />
                                            <TableHeaderCell label="Action Details" minWidth={100} />
                                            <TableHeaderCell label="Job Status" minWidth={100} />
                                            <TableHeaderCell label="Action Time Stamp" minWidth={100} />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {paginatedData.map((row, i) => {
                                            const color = Utils.getColorByStatus(row.ActionStatus);

                                            return (
                                                <StyledTableRow key={`ALT-${i}`}>
                                                    <TableBodyCell label={row.JobID} />
                                                    <TableBodyCell label={Utils.formatDate(row.JobRunDate)} />
                                                    <TableBodyCell label={row.ActionType} />
                                                    <TableBodyCell label={row.ActionDetail} />
                                                    <TableBodyCell label={row.ActionStatus} style={{ color }} />
                                                    <TableBodyCell label={Utils.formatDateTime(row.ActionTimeStamp)} />
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <InfinitePagination pageSizeKey={PageSizeKey.AuditLog}
                            isPrevious={enablePreviousButton} isNext={enableNextButton}
                            onNextClick={this.handleNextClick} onPreviousClick={this.handlePreviousClick} onPageSizeChange={this.handlePageSizeChange} />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    handleNextClick = () => {
        const { onAuditLogNextClick, useLocalPagination } = this.props;

        if (useLocalPagination) {
            this.setState(prevState => ({
                pageNumber: prevState.pageNumber + 1,
            }));
        } else {
            onAuditLogNextClick();
        }
    }

    handlePreviousClick = () => {
        const { onAuditLogPreviousClick, useLocalPagination } = this.props;

        if (useLocalPagination) {
            this.setState(prevState => ({
                pageNumber: prevState.pageNumber > 1 ? prevState.pageNumber - 1 : 1,
            }));
        } else {
            onAuditLogPreviousClick();
        }
    }

    handlePageSizeChange = () => {
        const { onPageSizeChange, useLocalPagination } = this.props;

        if (useLocalPagination) {
            this.setState({ pageNumber: 1 });
        } else {
            this.setState({ pageNumber: 1 }, onPageSizeChange);
        }
    }

    handleDateChange = (selectedDate: Date | null, targetId?: string) => {
        if (targetId) {
            this.setState({ pageNumber: 1 }, () => {
                this.props.onDateChange(targetId, selectedDate);
            });
        }
    }
}

export default AuditLogTable;