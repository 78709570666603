import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import { Grid } from '@material-ui/core';

import TabBar from "./TabBar";
import { TabModel, FormType } from "./TabModel";
import PageHeader from '../../Components/Text/PageHeader';
import Alert from '../../Components/Alert/Alert';
import { AuditLogDTO, DashboardChartModel, JobDetailDTO, JobResponseDetailDTO, AuditLogResponseDTO } from '../../Models';
import { CustomEventHandlerService, MonitoringAuditTrailService } from '../../Services';
import Dashboard from './Dashboard/Dashboard';
import Utils from '../../Common/Utils';
import { CustomEventType, PageSizeKey } from '../../Common/Enums';
import moment from 'moment';
import _ from 'lodash';

interface LocationState {
    tabName: FormType;
    jobId: string;
}

interface Props extends RouteComponentProps<{}, any, LocationState> { }
interface State {
    currentTab: TabModel;
    jobDetails: JobDetailDTO[];
    jobResponseDetails: JobResponseDetailDTO[];
    jobDetailsIndex: number;
    auditLogs: AuditLogDTO[];
    auditLogResponses: AuditLogResponseDTO[];
    auditLogIndex: number;
    filterAuditLogs: AuditLogDTO[];
    dashboardData: DashboardChartModel[];
    selectedDate: Date | null;
    fromDate: Date | null;
    toDate: Date | null;
}
class MonitoringAuditTrail extends React.Component<Props, State> {
    readonly Tabs: TabModel[] = [
        {
            name: 'Job Run Status',
            type: 'JobRunStatus',
            index: 0,
        },
        {
            name: 'Data Availability',
            type: 'DataAvailability',
            index: 1,
        },
        {
            name: 'Audit Logs',
            type: 'AuditLogs',
            index: 2,
        },
    ];

    constructor(props: Props | Readonly<Props>) {
        super(props);

        const toDate = new Date();
        const fromDate = moment(toDate).subtract(1, 'M').toDate();

        this.state = {
            currentTab: this.Tabs[0],
            jobDetails: [],
            jobResponseDetails: [],
            jobDetailsIndex: 0,
            auditLogs: [],
            auditLogIndex: 0,
            auditLogResponses: [],
            filterAuditLogs: [],
            dashboardData: [],
            selectedDate: null,
            fromDate: fromDate,
            toDate: toDate,
        }
    }

    componentDidMount() {
        this.loadData(this.Tabs[0]);
    }

    render() {
        const {
            currentTab, jobResponseDetails, jobDetailsIndex, dashboardData,
            selectedDate, fromDate, toDate, auditLogResponses, auditLogIndex
        } = this.state;
        const { location } = this.props;

        let jobDetails: JobDetailDTO[] = [];
        let auditLogs: AuditLogDTO[] = [];
        let isLocalPagination = false;

        if (fromDate && toDate) {
            isLocalPagination = true;
        }

        if (currentTab.type === 'AuditLogs') {
            isLocalPagination = selectedDate !== null;

            if (location.state && location.state.jobId) {
                isLocalPagination = true;
            }
        }

        if (currentTab.type === 'AuditLogs') {
            if (auditLogResponses.length > 0) {
                auditLogs = auditLogResponses[auditLogIndex].filterAuditLogsData;
            }
        } else {
            if (jobResponseDetails.length > 0) {
                jobDetails = jobResponseDetails[jobDetailsIndex].filterJobDetailsData;
            }
        }

        return (
            <div>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <PageHeader label="Monitoring / Audit Trail" />
                    <Alert />
                </Grid>
                <br />
                <Dashboard dashboardData={dashboardData} />
                <TabBar
                    currentTab={currentTab} tabs={this.Tabs} onTabChange={this.handleTabChange}
                    jobDetails={jobDetails} auditLogs={auditLogs} jobDetailsIndex={jobDetailsIndex} auditLogIndex={auditLogIndex}
                    onJobDetailsNextClick={this.handleJobDetailsNextClick} onJobDetailsPreviousClick={this.handleJobDetailsPreviousClick}
                    onAuditLogNextClick={this.handleAuditLogNextClick} onAuditLogPreviousClick={this.handleAuditLogPreviousClick}
                    selectedDate={selectedDate} onPageSizeChange={this.handlePageSizeChange} isLocalPagination={isLocalPagination}
                    fromDate={fromDate} toDate={toDate} onDateChange={this.handleDateChange}
                />
            </div>
        )
    }

    handleTabChange = async (selectedTab: TabModel) => {
        if (selectedTab.index === 2) {
            CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

            let selectedDate = null;
            const toDate = new Date();
            const fromDate = moment(toDate).subtract(1, 'M').toDate();
            const JobRunDateFrom = Utils.formatJSDate(fromDate);
            const JobRunDateTo = Utils.formatJSDate(toDate);

            const auditLogPageSize = Utils.getPageSize(PageSizeKey.AuditLog);

            let auditLogs: AuditLogDTO[] = [];

            const auditLogResponses: AuditLogResponseDTO[] = [];
            let auditLogResponse = await MonitoringAuditTrailService.getAuditLogs({
                PageSize: auditLogPageSize, JobID: '', LogID: '', ActionTimeStamp: '', JobRunDateFrom, JobRunDateTo,
            });
            if (auditLogResponse) {
                const maxAuditLog = _.maxBy(auditLogResponse.filterAuditLogsData, (auditLog) => {
                    return moment(auditLog.JobRunDate).toDate();
                });

                if (maxAuditLog) {
                    selectedDate = moment(maxAuditLog.JobRunDate).toDate();
                    auditLogResponse.filterAuditLogsData = auditLogResponse.filterAuditLogsData.filter(p =>
                        p.JobRunDate === maxAuditLog.JobRunDate);
                } else {
                    auditLogs = auditLogResponse.filterAuditLogsData;
                }

                auditLogResponses.push(auditLogResponse);
            }

            this.setState({ currentTab: selectedTab, auditLogs, auditLogResponses, auditLogIndex: 0, selectedDate }, () => {
                CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
            });
        } else {
            await this.loadData(selectedTab, true);
        }
    }

    handleJobDetailsNextClick = async () => {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        const { jobResponseDetails, jobDetailsIndex, fromDate, toDate } = this.state;
        const prevJobResponseDetail = jobResponseDetails[jobDetailsIndex];
        const { JobRunDate, JobID } = prevJobResponseDetail.lastEvaluatedKey;
        const jobStatusPageSize = Utils.getPageSize(PageSizeKey.JobRunStatus);
        const jobResponseDetail = await MonitoringAuditTrailService.getJobDetails({
            PageSize: jobStatusPageSize, JobID, JobRunDate, JobRunDateFrom: Utils.formatJSDate(fromDate), JobRunDateTo: Utils.formatJSDate(toDate)
        });

        if (jobResponseDetail) {
            this.setState(prevState => ({
                jobResponseDetails: [...prevState.jobResponseDetails, jobResponseDetail],
                jobDetailsIndex: prevState.jobDetailsIndex + 1,
            }));
        }

        CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
    }

    handleJobDetailsPreviousClick = async () => {
        this.setState(prevState => ({
            jobDetailsIndex: prevState.jobDetailsIndex - 1,
        }));
    }

    handleAuditLogPreviousClick = async () => {
        this.setState(prevState => ({
            auditLogIndex: prevState.auditLogIndex - 1,
        }));
    }

    handleAuditLogNextClick = async () => {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        const { auditLogResponses, auditLogIndex, selectedDate } = this.state;
        const prevAuditLogResponseDetail = auditLogResponses[auditLogIndex];
        const { ActionTimeStamp, LogID } = prevAuditLogResponseDetail.lastEvaluatedKey;
        const auditLogPageSize = Utils.getPageSize(PageSizeKey.AuditLog);
        const auditLogResponse = await MonitoringAuditTrailService.getAuditLogs({
            PageSize: auditLogPageSize, JobID: '', LogID, ActionTimeStamp, JobRunDateFrom: Utils.formatJSDate(selectedDate), JobRunDateTo: Utils.formatJSDate(selectedDate)
        });

        if (auditLogResponse) {
            this.setState(prevState => ({
                auditLogResponses: [...prevState.auditLogResponses, auditLogResponse],
                auditLogIndex: prevState.auditLogIndex + 1,
            }));
        }

        CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
    }

    handlePageSizeChange = async (tab: TabModel) => {
        if (tab.type === 'JobRunStatus' || tab.type === 'DataAvailability') {
            this.handleJobStatusTableUpdate();
        }
    }

    handleDateChange = (id: string, selectedDate: Date | null) => {
        const { fromDate, toDate } = this.state;

        if (id === 'fromDate') {
            this.setState({ fromDate: selectedDate }, () => {
                if (toDate) {
                    this.handleJobStatusTableUpdate();
                }
            });
        } else if (id === 'toDate') {
            this.setState({ toDate: selectedDate }, () => {
                if (fromDate) {
                    this.handleJobStatusTableUpdate();
                }
            });
        } else if (id === 'selectedDate') {
            this.setState({ selectedDate }, () => {
                this.handleAuditLogTableUpdate();
            });
        }
    }

    handleJobStatusTableUpdate = async () => {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        const { fromDate, toDate } = this.state;
        const jobStatusPageSize = Utils.getPageSize(PageSizeKey.JobRunStatus);
        const jobResponseDetail = await MonitoringAuditTrailService.getJobDetails({
            PageSize: jobStatusPageSize, JobID: '', JobRunDate: '', JobRunDateFrom: Utils.formatJSDate(fromDate), JobRunDateTo: Utils.formatJSDate(toDate)
        });
        let jobDetails: JobDetailDTO[] = [];

        const jobResponseDetails: JobResponseDetailDTO[] = [];
        if (jobResponseDetail) {
            jobDetails = jobResponseDetail.filterJobDetailsData;
            jobResponseDetails.push(jobResponseDetail);
        }

        this.setState({ jobDetails, jobResponseDetails, jobDetailsIndex: 0 }, () => {
            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        });
    }

    handleAuditLogTableUpdate = async () => {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        const { selectedDate } = this.state;
        const auditLogPageSize = Utils.getPageSize(PageSizeKey.AuditLog);
        const auditLogResponse = await MonitoringAuditTrailService.getAuditLogs({
            PageSize: auditLogPageSize, JobID: '', LogID: '', ActionTimeStamp: '', JobRunDateFrom: Utils.formatJSDate(selectedDate), JobRunDateTo: Utils.formatJSDate(selectedDate)
        });
        let auditLogs: AuditLogDTO[] = [];

        const auditLogResponses: AuditLogResponseDTO[] = [];
        if (auditLogResponse) {
            auditLogs = auditLogResponse.filterAuditLogsData;
            auditLogResponses.push(auditLogResponse);
        }

        this.setState({ auditLogs, auditLogResponses, auditLogIndex: 0 }, () => {
            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        });
    }

    loadData = async (currentTab: TabModel, force: boolean = false) => {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        const { location } = this.props;
        const { fromDate, toDate } = this.state;
        const JobRunDateFrom = Utils.formatJSDate(fromDate);
        const JobRunDateTo = Utils.formatJSDate(toDate);

        let pageSize = Utils.getPageSize(PageSizeKey.JobRunStatus);

        if (currentTab.type === 'DataAvailability') {
            pageSize = Utils.getPageSize(PageSizeKey.DataAvailability);
        }
        let dashboardData: DashboardChartModel[] = this.state.dashboardData;

        if (dashboardData.length === 0) {
            dashboardData = await MonitoringAuditTrailService.getJobChartDetails();
        }

        const jobResponseDetail = await MonitoringAuditTrailService.getJobDetails({
            PageSize: pageSize, JobID: '', JobRunDate: '', JobRunDateFrom, JobRunDateTo,
        });
        let jobDetails: JobDetailDTO[] = [];

        const jobResponseDetails: JobResponseDetailDTO[] = [];
        if (jobResponseDetail) {
            jobDetails = jobResponseDetail.filterJobDetailsData;
            jobResponseDetails.push(jobResponseDetail);
        }
        let auditLogs: AuditLogDTO[] = [];
        const auditLogResponses: AuditLogResponseDTO[] = [];

        if (location.state && location.state.tabName) {
            const tab = this.Tabs.find(p => p.type === location.state.tabName);

            if (force === false && tab) {
                currentTab = tab;
            }

            if (location.state.jobId) {
                const auditLogPageSize = Utils.getPageSize(PageSizeKey.AuditLog);

                const auditLogResponse = await MonitoringAuditTrailService.getAuditLogs({
                    PageSize: auditLogPageSize, JobID: location.state.jobId, LogID: '', ActionTimeStamp: '', JobRunDateFrom, JobRunDateTo,
                });
                if (auditLogResponse) {
                    auditLogs = auditLogResponse.filterAuditLogsData;
                    auditLogResponses.push(auditLogResponse);
                }
            }
        }

        this.setState({ currentTab, jobDetails, jobResponseDetails, auditLogs, auditLogResponses, dashboardData, fromDate, toDate }, () => {
            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        });
    }
}

export default MonitoringAuditTrail;
