import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from '@material-ui/core';

import './Assets/App.css';
import App from './App';
import Constants from './Common/Constants';
import Middlewares from './Middlewares';

Middlewares.apply();

const theme = createTheme({
  typography: {
    fontFamily: [
      Constants.DefaultFontFamily,
    ].join(','),
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
