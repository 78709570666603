import React from "react";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { WithStyles, withStyles, Theme, Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';

const styles = (theme: Theme) => ({
    notchedOutline: {
        border: '1px solid #E0E0E0',
        borderRadius: '4px',
    },
});

interface Props extends WithStyles<typeof styles> {
    id?: string;
    label: string;
    dateFormat: string;
    selectedDate: Date | null;
    onChange: ((selectedDate: Date | null, targetId?: string) => void);
}
interface State {
    selectedDate: Date | null;
}
class InlineDatePicker extends React.Component<Props, State> {
    public static defaultProps = {
        dateFormat: 'YYYY-MM-DD'
    };

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            selectedDate: props.selectedDate === undefined ? null : props.selectedDate,
        };
    }

    render() {
        const { id, label, selectedDate, dateFormat, classes } = this.props;

        const labelId = id ? id : label;

        return (
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}>
                <Grid item>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#020202' }}>{label}</Typography>
                </Grid>
                <Grid item>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            style={{ margin: 0 }}
                            autoOk={true}
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            format={dateFormat}
                            margin="normal"
                            id={labelId}
                            placeholder="Select"
                            value={selectedDate}
                            onChange={this.handleChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            InputProps={{
                                classes: classes,
                            }}
                            InputLabelProps={{
                                style: { marginLeft: 5 }
                            }}
                            keyboardIcon={<FontAwesomeIcon icon={faCalendarAlt} size="xs" color="#EE3A29" />}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
        );
    }

    handleChange = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
        const selectedDate: Date | null = date ? date.toDate() : null;
        this.props.onChange(selectedDate, this.props.id);
    }
}

export default withStyles(styles)(InlineDatePicker);