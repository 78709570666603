import React from "react";
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
} from "@material-ui/core";

import { SourceCredentialDTO } from "../../../Models";
import TableHeaderCell from "../../../Components/Table/TableHeaderCell";
import TableBodyCell from "../../../Components/Table/TableBodyCell";
import StyledTableRow from "../../../Components/Table//StyledTableRow";
import LinkButton from "../../../Components/Button/LinkButton";
import Utils from "../../../Common/Utils";


interface Props {
    sourceCredentials: SourceCredentialDTO[];
    onCredentialModify: ((sourceCredential: SourceCredentialDTO) => void);
}
interface State { }
class SourceCredentialTable extends React.Component<Props, State> {
    render() {
        const { sourceCredentials, onCredentialModify } = this.props;

        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead style={{ backgroundColor: '#EEEEEE' }}>
                        <TableRow>
                            <TableHeaderCell label="Client ID" minWidth={100} />
                            <TableHeaderCell label="Client Secret" minWidth={100} />
                            <TableHeaderCell label="API Url" minWidth={100} />
                            <TableHeaderCell label="Token Url" minWidth={100} />
                            <TableHeaderCell label="Authorization" minWidth={100} />
                            <TableHeaderCell label="Grant Type" minWidth={100} />
                            <TableHeaderCell label="Content Type" minWidth={100} />
                            <TableHeaderCell label="" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sourceCredentials.map((row, i) => (
                            <StyledTableRow key={`SCT-${i}`}>
                                <TableBodyCell label={row.CLIENT_ID} />
                                <TableBodyCell label={Utils.getChars('*', 15)} />
                                <TableBodyCell label={row.API_URL} />
                                <TableBodyCell label={row.TOKEN_URL} />
                                <TableBodyCell label={Utils.getChars('*', 15)} />
                                <TableBodyCell label={row.grant_type} />
                                <TableBodyCell label={row["Content-Type"]} />
                                <TableCell>
                                    <LinkButton color="#868686" onClick={() => onCredentialModify(row)}>Modify</LinkButton>
                                </TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default SourceCredentialTable;