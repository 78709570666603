import React from 'react';
import { Divider, Grid } from '@material-ui/core';

import PageHeader from '../../Components/Text/PageHeader';
import Alert from '../../Components/Alert/Alert';
import { APIDetailDTO, JobDetailDTO, RunJobResponseDTO } from '../../Models';
import { CustomEventHandlerService, JobManagementService } from '../../Services';
import APIDetailForm from './APIDetail/APIDetailForm';
import RedButton from '../../Components/Button/RedButton';
import JobDetailTable from './JobDetail/JobDetailTable';
import { CustomEventType } from '../../Common/Enums';
import WhiteButton from '../../Components/Button/WhiteButton';


interface Props { }
interface State {
    jobDetails: JobDetailDTO[];
    apiDetailFormDTO: APIDetailDTO;
    runJobResponseDTO: RunJobResponseDTO | null;
}
class JobManagement extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            jobDetails: [],
            apiDetailFormDTO: {} as APIDetailDTO,
            runJobResponseDTO: null,
        }
    }

    async componentDidMount() {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        const jobDetails = await JobManagementService.getJobDetails();

        const apiDetailFormDTO = await JobManagementService.getAPIDetail();

        this.setState({ apiDetailFormDTO, jobDetails }, () => {
            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        });
    }

    render() {
        const {
            apiDetailFormDTO, jobDetails, runJobResponseDTO,
        } = this.state;

        return (
            <div>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <PageHeader label="Job Management" />
                </Grid>
                <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10 }} />
                <Grid
                    container
                    direction="row"
                    spacing={3}>
                    <Grid item xs={12}>
                        <APIDetailForm apiDetail={apiDetailFormDTO} />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={5}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <RedButton label="Run Job"
                                            buttonType="Dialog" dialogMessage="Are you sure, you want to run the job?"
                                            onClick={this.handleRunJobClick} />
                                    </Grid>
                                    <Grid item>
                                        {runJobResponseDTO != null ? <WhiteButton label={`Refresh`} onClick={this.handleRefreshJobClick} /> : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Alert />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <JobDetailTable jobDetails={jobDetails} />
                    </Grid>
                </Grid>
            </div>
        );
    }

    handleRunJobClick = async () => {
        const runJobResponseDTO = await JobManagementService.runJob();
        this.setState({ runJobResponseDTO });
    }

    handleRefreshJobClick = async () => {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        const jobDetails = await JobManagementService.getJobDetails();

        this.setState({ jobDetails }, () => {
            const { runJobResponseDTO } = this.state;

            if (runJobResponseDTO) {
                const jobs = jobDetails.filter(p => p.JobID === runJobResponseDTO.jobId);

                if (jobs.length > 0) {
                    CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, 'Execution Successfully Completed.');
                }
            }

            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        });
    }
}

export default JobManagement;