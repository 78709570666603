import { FileDTO } from "../Models";
import CMSMiddlewareAPIService from "./CMSMiddlewareAPIService";

class FileService extends CMSMiddlewareAPIService {
    async getFile(key: string, fileType: string): Promise<string | null> {
        const fileObj = {
            key,
            fileType,
        };

        const filePreSignedResponse = await this.PostAsync<FileDTO>('s3download', fileObj);

        if (filePreSignedResponse.isSuccess) {
            return filePreSignedResponse.data.presigned_url;
        }

        return null;
    }
}

export default new FileService();