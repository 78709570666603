import React from "react";
import { InputLabel, TextField } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

import { ValidationType } from "../../Common/Enums";
import ValidationUtils from "../../Common/ValidationUtils";

interface Props {
    id: string;
    label?: string;
    placeholder?: string;
    isReadonly: boolean;
    isDisabled: boolean;
    isRequired: boolean;
    endAdornment?: React.ReactNode;
    classes?: Partial<ClassNameMap<string>>;
    value?: string | number;
    inputType: string;
    validationType: ValidationType;
    onChange: ((event: React.ChangeEvent<HTMLInputElement>, isValid: boolean) => void);
}
interface State {
    helperText: string;
    value: string;
}
class TextInputField extends React.Component<Props, State> {
    public static defaultProps = {
        isReadonly: false,
        isDisabled: false,
        isRequired: true,
        inputType: 'text',
        validationType: ValidationType.NONE,
    };

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            helperText: '',
            value: props.value ? props.value.toString() : '',
        };
    }

    componentDidUpdate(prevProps: Props) {
        const { value: propsValue } = this.props;
        const { value: prevPropsValue } = prevProps;

        if (propsValue !== undefined && propsValue !== prevPropsValue) {
            this.setState({ value: propsValue ? propsValue.toString() : '' });
        }
    }

    render() {
        const { id, label, isReadonly, isDisabled, isRequired, endAdornment, classes, inputType, placeholder } = this.props;
        const { helperText, value } = this.state;

        return (
            <div>
                <InputLabel style={{
                    marginBottom: 10,
                    color: '#5D5D5D',
                    fontSize: 15,
                    fontWeight: 500,
                }}>{label}</InputLabel>

                <TextField type={inputType} variant="outlined" fullWidth required={isRequired} helperText={helperText}
                    placeholder={placeholder} InputProps={{ readOnly: isReadonly, endAdornment, classes }} disabled={isDisabled}
                    id={id} value={value} onChange={this.handleChange} />
            </div>
        );
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        this.setState({ value, helperText: '' }, () => {
            const isValid = this.isValid();
            this.props.onChange(event, isValid);
        });
    }

    isValid = () => {
        const { validationType, isRequired } = this.props;
        const { value } = this.state;

        let validationResult = ValidationUtils.validate(validationType, value);

        if (validationResult.isValid && isRequired) {
            validationResult = ValidationUtils.validate(ValidationType.REQUIRED, value);
        }

        if (!validationResult.isValid) {
            this.setState({ helperText: validationResult.message });
            return false;
        }

        return true;
    }
}

export default TextInputField;
