import React from 'react';
import { Box, Card, CardContent } from '@material-ui/core';

import Header from './Header';
import Footer from './Footer';
import BackdropLoader from '../Loader/BackdropLoader';
import { AuthProviderService, CustomEventHandlerService } from '../../Services';
import { CustomEventType } from '../../Common/Enums';

interface Props { }
interface State {
    showMenu: boolean;
    isLoading: boolean;
}
class Layout extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            isLoading: true,
            showMenu: false,
        };
    }

    async componentDidMount() {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);
        await this.checkAdminUser();
    }

    async componentDidUpdate() {
        await this.checkAdminUser();
    }

    render() {
        const { showMenu } = this.state;

        return (
            <div style={{ backgroundColor: '#F5F5F5' }}>
                <Header showMenu={showMenu} />
                <Box>
                    <Card style={{ margin: 20 }}>
                        <CardContent style={{ minHeight: window.innerHeight - 320 }}>
                            <BackdropLoader />
                            {this.props.children}
                        </CardContent>
                    </Card>
                    <Footer />
                </Box>
            </div>
        )
    }

    checkAdminUser = async () => {
        const isLoggedIn = await AuthProviderService.isLoggedIn();

        if (isLoggedIn) {
            const isAdminUser = await AuthProviderService.isAdminUser();

            if (this.state.isLoading) {
                // Pages responsible to stop loading
                // CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
                this.setState({ showMenu: isAdminUser, isLoading: false });
            }
        }
    }
}

export default Layout;