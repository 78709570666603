import React from "react";
import { InputLabel } from "@material-ui/core";


interface Props {
    label?: string;
    value?: string | number;
    valueStyle?: React.CSSProperties;
}
interface State { }
class TextField extends React.Component<Props, State> {
    render() {
        const { label, value, valueStyle } = this.props;

        let defaultValueStyle: React.CSSProperties = { marginBottom: 10, color: '#000000', fontSize: 15, fontWeight: 400 };
        if (valueStyle) {
            defaultValueStyle = Object.assign(defaultValueStyle, valueStyle);
        }

        return (
            <div>
                <InputLabel style={{
                    marginBottom: 10,
                    color: '#8E8E8E',
                    fontSize: 15,
                    fontWeight: 500,
                }}>{label}</InputLabel>
                <InputLabel style={defaultValueStyle}>{value}</InputLabel>
            </div>
        );
    }
}

export default TextField;