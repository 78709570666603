import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export class RESTClient {
    private readonly _config: AxiosRequestConfig;

    constructor(baseURL: string) {
        this._config = {
            baseURL: baseURL
        };
    }

    async get<T>(path: string): Promise<RESTResponse<T>> {
        let result = new RESTResponse<T>();

        try {
            const response = await axios.get(path, this._config);
            result = this.extractObj(response);
        } catch (err) {
            result = this.extractError(err);
        }

        return result;
    }

    async post<T>(path: string, data: any): Promise<RESTResponse<T>> {
        let result = new RESTResponse<T>();

        try {
            const response = await axios.post(path, data, this._config);
            result = this.extractObj(response);
        } catch (err) {
            result = this.extractError(err);
        }

        return result;
    }

    async put<T>(path: string, data: any): Promise<RESTResponse<T>> {
        let result = new RESTResponse<T>();

        try {
            const response = await axios.put(path, data, this._config);
            result = this.extractObj(response);
        } catch (err) {
            result = this.extractError(err);
        }

        return result;
    }

    async delete<T>(path: string): Promise<RESTResponse<T>> {
        let result = new RESTResponse<T>();

        try {
            const response = await axios.delete(path, this._config);
            result = this.extractObj(response);
        } catch (err) {
            result = this.extractError(err);
        }

        return result;
    }

    private extractObj<T>(response: AxiosResponse<any>): RESTResponse<T> {
        const result = new RESTResponse<T>();

        result.data = response.data.data;
        result.message = response.data.message;
        result.isSuccess = true;
        result.statusCode = response.status;

        return result;
    }

    private extractError<T>(err: any): RESTResponse<T> {
        const result = new RESTResponse<T>();
        const error = err as AxiosError;

        result.message = error.message;

        if (error.response) {
            result.isSuccess = false;
            result.statusCode = error.response.status;
        }

        return result;
    }
}

export class RESTResponse<T> {
    isSuccess!: boolean;
    statusCode!: number;
    data!: T;
    message!: string;
}