import React from "react";
import {
    Grid,
} from "@material-ui/core";

import SimpleSelect from "../Select/SimpleSelect";
import LinkButton from "../Button/LinkButton";
import Constants from "../../Common/Constants";
import { PageSizeKey } from "../../Common/Enums";
import Utils from "../../Common/Utils";

interface Props {
    isPrevious: boolean;
    isNext: boolean;
    pageSizeKey: PageSizeKey;
    onNextClick: (() => void);
    onPreviousClick: (() => void);
    onPageSizeChange: (() => void);
}
interface State {
    pageSize: number;
    pageSizeOptions: number[];
}
class InfinitePagination extends React.Component<Props, State> {
    public static defaultProps = {
        isPrevious: false,
        isNext: true,
    }

    constructor(props: Props | Readonly<Props>) {
        super(props);

        const pageSize = Utils.getPageSize(props.pageSizeKey);

        this.state = {
            pageSize,
            pageSizeOptions: Constants.PageSizeOptions,
        };
    }

    render() {
        const { isPrevious, isNext, pageSizeKey, onNextClick, onPreviousClick } = this.props;
        const { pageSize, pageSizeOptions } = this.state;

        return (
            <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                <Grid item>
                    <Grid item container spacing={1}>
                        {isPrevious ? <Grid item>
                            <LinkButton onClick={onPreviousClick}>{'<  Previous'}</LinkButton>
                        </Grid> : null}
                        {isNext ? <Grid item>
                            <LinkButton onClick={onNextClick}>{'Next  >'}</LinkButton>
                        </Grid> : null}
                    </Grid>
                </Grid>
                <Grid item>
                    <SimpleSelect id={pageSizeKey} value={pageSize} options={pageSizeOptions}
                        onChange={this.handlePageSizeChange} />
                </Grid>
            </Grid>
        );
    }

    handlePageSizeChange = (id: string, value: string) => {
        const pageSize = Number.parseInt(value);
        Utils.setPageSize(this.props.pageSizeKey, pageSize);
        this.setState({ pageSize });
        this.props.onPageSizeChange();
    }
}

export default InfinitePagination;