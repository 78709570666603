import React from "react";
import * as d3 from 'd3';

import ChartModel from "./ChartModel";
import Constants from "../../Common/Constants";

interface Props {
    id: string;
    data: ChartModel[];
}
interface State { }
class DoughnutChart extends React.Component<Props, State> {
    componentDidMount() {
        this.drawChart();
    }

    componentDidUpdate() {
        this.drawChart();
    }

    render() {
        const { id } = this.props;
        return <div id={id}></div>;
    }

    drawChart = () => {
        const { data, id } = this.props;

        const chartElement = document.getElementById(id);

        if (chartElement) {
            chartElement.innerHTML = '';
        }

        const width = 500;
        const height = 350;
        const margin = 30;

        const radius = Math.min(width, height) / 3 - margin;
        // legend Position
        const legendPosition = d3.arc().innerRadius(radius / 0.85).outerRadius(radius);
        const pieArcData = d3.pie()(data.map(p => p.value));
        const totalValue = d3.sum(pieArcData, (d: { value: any; }) => d.value);
        const totalPercent = d3.format("0.1%");
        const colors = data.map(d => d.color);

        const scaleOrdinalColor = d3.scaleOrdinal()
            .domain(colors)
            .range(colors);

        const svgWidth = 500;
        const svgHeight = 220;
        // Create SVG
        const svg = d3.select(`#${id}`)
            .append('svg')
            .attr("width", svgWidth)
            .attr("height", svgHeight)
            .attr('viewBox', '170 30 ' + 380 + ' ' + 300)
            .append("g")
            .attr("transform", "translate(" + Math.min(width, height) / 2 + "," + Math.min(width, height) / 2 + ")");

        const newArc = d3.arc()
            .innerRadius(radius / 1.45)
            .outerRadius(radius)
            .cornerRadius(3)
            .padAngle(0.060);

        // Center text
        svg.append("text")
            .attr("text-anchor", "middle")
            .text(totalValue)
            .attr("font-family", Constants.DefaultFontFamily)
            .style("font-size", 26)
            .attr("y", ".3em");

        // Donut partition  
        svg
            .selectAll('whatever')
            .data(pieArcData)
            .enter()
            .append('path')
            .attr('d', newArc as any)
            .attr('fill', function (d: any) {
                return scaleOrdinalColor(d);
            })
            .attr("stroke", "#fff")
            .style("stroke-width", "0")
            .style("opacity", "0.8");

        // Legend group and legend name 
        svg
            .selectAll('mySlices')
            .data(pieArcData)
            .enter()
            .append('g')
            .attr("transform", function (d: any) {
                return `translate(${legendPosition.centroid(d as any)})`;
            })
            .attr("class", 'legend-g')
            .style("user-select", "none")
            .append('text')
            .text(function (d: any, i: any) {
                if (pieArcData[i].value === 0) {
                    return "";
                }

                //return pieArcData[i].value;
                return `(${totalPercent(d.value / totalValue)})`;
            })
            .style("text-anchor", "middle")
            .style("fill", '#5D5D5D')
            .attr("font-family", Constants.DefaultFontFamily)
            .style("font-size", 20)
            .attr("transform", function (d: any) {
                var c = legendPosition.centroid(d as any);
                return "translate(" + c[0] * 0.3 + "," + c[1] * 0.2 + ")";
            });

        var legend = svg.append("g")
            .attr("class", "legend")
            .attr("font-family", Constants.DefaultFontFamily)
            .attr("font-size", 22)
            .attr("transform", "translate(160,-30)")
            .selectAll("g")
            .data(pieArcData)
            .enter().append("g")
            .attr("transform", function (d: any, i: any) {
                const legendData = data.filter(p => p.value === d.data);
                const maxLengthLegend = legendData.reduce(function (a: any, b: any) {
                    return a.length > b.length ? a : b;
                });

                const maxNameLength = maxLengthLegend.name.length;
                var c = 1;   // number of columns
                var h = 30;  // legend entry height
                var w = data.length === 2 ? (400 - (maxNameLength * 7.5)) : 270; // legend entry width (so we can position the next column) 
                var tx = 0; // tx/ty are essentially margin values
                var ty = 20;
                var x = i % c * w + tx;
                var y = Math.floor(i / c) * h + ty;
                return "translate(" + x + "," + y + ")";
            });

        legend.append("text")
            .data(data)
            .attr("x", radius + 30)
            .attr("y", -50)
            .attr("fill", '#5D5D5D')
            .attr("dy", "0.32em")
            .text(function (d: any) { return d.name; });

        legend.append("circle")
            .attr("cx", radius + 10)
            .attr("cy", -51)
            .attr("r", 6)
            .style('fill', function (d: any) { return scaleOrdinalColor(d); });
    }
}

export default DoughnutChart;