import moment from "moment";
import jwtDecode from "jwt-decode";

import config from '../config.json';
import Constants from "./Constants";
import { JobStatus, JobStatusColor } from "./Enums";

class Utils {
    static getCurrentEnv(): any {
        if (process.env.APP_ENV) return process.env.APP_ENV;
        // Production
        if (
            window.location.hostname.includes('prod')
            || window.location.hostname === 'dunhxlinzeejz.cloudfront.net'
            || window.location.hostname === 'cmsmiddlewareservice.lilly.com'
        ) {
            return 'prod';
        }
        // Staging
        if (
            window.location.hostname.includes('qa')
            || window.location.hostname === 'd2v5f1i1z4hg5y.cloudfront.net'
            || window.location.hostname === 'qa.cmsmiddlewareservice.lilly.com'
        ) {
            return 'qa';
        }
        // Develop
        return 'dev';
    }

    static getEnvVars(): any {
        const env = Utils.getCurrentEnv();
        const envVariables: any = Object.assign({}, config.Environment);
        return envVariables[env];
    }

    static isIE() {
        const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1;
        const isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge;
        return isIE;
    }

    static isEqualDates(dateOne?: Date | null, dateTwo?: Date | null): boolean {
        if (dateOne && dateTwo) {
            return dateOne.getFullYear() === dateTwo.getFullYear()
                && dateOne.getDate() === dateTwo.getDate()
                && dateOne.getMonth() === dateTwo.getMonth();
        }

        return false;
    }

    static formatDateTime(date: string): string {
        if (date) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss');
        }

        return "";
    }

    static formatDate(date: string): string {
        if (date) {
            return moment(date).format('YYYY-MM-DD');
        }

        return "";
    }

    static formatJSDate(date: Date | null): string {
        if (date) {
            return moment(date).format('YYYY-MM-DD');
        }

        return "";
    }

    static getChars(char: string, n: number): string {
        return char.repeat(n);
    }

    static paginate<T>(array: T[], pageSize: number, pageNumber: number): T[] {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }

    static decodeJWT<T>(token: string | null): T | null {
        if (token) {
            const decoded = jwtDecode<T>(token);
            return decoded;
        }

        return null;
    }

    static setPageSize(key: string, pageSize: number): void {
        localStorage.setItem(key, pageSize.toString());
    }

    static getPageSize(key: string): number {
        const keyPageSize = localStorage.getItem(key);
        let pageSize = Constants.DefaultPageSize;

        if (keyPageSize) {
            pageSize = Number.parseInt(keyPageSize);
        }

        return pageSize;
    }

    static getColorByStatus(status: string) {
        let color = '';

        switch (status) {
            case JobStatus.Success:
                color = JobStatusColor.Success;
                break;
            case JobStatus.Running:
                color = JobStatusColor.Running;
                break;
            case JobStatus.Failed:
                color = JobStatusColor.Failed;
                break;
            default:
                break;
        }

        return color;
    }
}

export default Utils;