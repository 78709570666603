import { AuthProvider } from '@elilillyco/spa_auth';
import Utils from './Utils';

const env = Utils.getEnvVars();

const config = {
    clientId: env.clientId,
    scopes: env.scopes,
};

const authProvider = new AuthProvider(config);
export default authProvider;